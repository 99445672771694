<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-secondary">
    {{notifCount}}
  </span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification" style="overflow-y: auto;
height: 400px">
  <li class="">
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">الإشعارات</h6>
  </li>
  <li *ngFor="let notif of notifications" class="m-2 card p-2">
    <p class="f-14" *ngIf="notif.notification.order"
      routerLink="{{'/v1/admin/order-management/' + notif.notification.order.id}}">
      <i class="fa fa-circle-o mr-3 font-danger" *ngIf="notif.isSeen == false" (click)="markAsSeen(notif.id)"></i>
      <i class="fa fa-circle-o mr-3 font-primary" *ngIf="notif.isSeen == true" (click)="markAsUnSeen(notif.id)"></i>
     <b>"{{notif.notification.title}}"</b> 
     <br>
      {{notif.notification.content}}
      <!--

      -->

    </p>
    

    <p class="f-14" *ngIf="notif.notification.user"
    routerLink="{{'/v1/admin/user-management/laundry-owner-management/' + notif.notification.user.id}}">
    <i class="fa fa-circle-o mr-3 font-danger" *ngIf="notif.isSeen == false" (click)="markAsSeen(notif.id)"></i>
    <i class="fa fa-circle-o mr-3 font-primary" *ngIf="notif.isSeen == true" (click)="markAsUnSeen(notif.id)"></i>
   <b>"{{notif.notification.title}}"</b> 
   <br>
    {{notif.notification.content}}
    <!--

    -->

  </p>

    <p class="f-14" *ngIf="notif.notification.laundry"
      routerLink="{{'/v1/admin/laundries-management/' + notif.notification.laundry.id}}">
      <i class="fa fa-circle-o mr-3 font-danger" *ngIf="notif.isSeen == false" (click)="markAsSeen(notif.id)"></i>
      <i class="fa fa-circle-o mr-3 font-primary" *ngIf="notif.isSeen == true" (click)="markAsUnSeen(notif.id)"></i>
      <b>"{{notif.notification.title}}"</b> 
      <br>
       {{notif.notification.content}}
      <!--

      -->

    </p>

    <p class="f-14" *ngIf="notif.notification.customOrder"
      routerLink="{{'/v1/admin/custom-order-management/' + notif.notification.customOrder.id}}">
      <i class="fa fa-circle-o mr-3 font-danger" *ngIf="notif.isSeen == false" (click)="markAsSeen(notif.id)"></i>
      <i class="fa fa-circle-o mr-3 font-primary" *ngIf="notif.isSeen == true" (click)="markAsUnSeen(notif.id)"></i>
      <b>"{{notif.notification.title}}"</b> 
      <br>
       {{notif.notification.content}}
      <!--

      -->

    </p>

    <p class="f-14" *ngIf="notif.notification.payout" routerLink="{{'/v1/admin/payout-management/'}}">
      <i class="fa fa-circle-o mr-3 font-danger" *ngIf="notif.isSeen == false" (click)="markAsSeen(notif.id)"></i>
      <i class="fa fa-circle-o mr-3 font-primary" *ngIf="notif.isSeen == true" (click)="markAsUnSeen(notif.id)"></i>
      <b>"{{notif.notification.title}}"</b> 
     <br>
      {{notif.notification.content}}
      <!--

      -->

    </p>

    <p class="f-14" *ngIf="!notif.notification.order && !notif.notification.payout && !notif.notification.customOrder && !notif.notification.laundry && !notif.notification.user">
      <i class="fa fa-circle-o mr-3 font-danger" *ngIf="notif.isSeen == false" (click)="markAsSeen(notif.id)"></i>
      <i class="fa fa-circle-o mr-3 font-primary" *ngIf="notif.isSeen == true" (click)="markAsUnSeen(notif.id)"></i>
      <b>"{{notif.notification.title}}"</b> 
     <br>
      {{notif.notification.content}}
      <!--

      -->

    </p>
  </li>

  <li>
    <a class="btn btn-info" (click)="markAsAllSeen()">اشر عليها بانها قرات</a>
  </li>
</ul>