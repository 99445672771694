import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "settings",
    loadChildren: () =>
      import(
        "src/app/features/admin/account-settings/account-settings.module"
      ).then((m) => m.AccountSettingsModule),
  },
  {
    path: "contact-messages",
    loadChildren: () =>
      import(
        "src/app/features/admin/contact-messages/contact-messages.module"
      ).then((m) => m.ContactMessagesModule),
  },
  {
    path: "content-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/content-management/content-management.module"
      ).then((m) => m.ContentManagementModule),
  },
  {
    path: "laundry-incomes",
    loadChildren: () =>
      import(
        "src/app/features/admin/laundry-incomes/laundry-incomes.module"
      ).then((m) => m.LaundryIncomesModule),
  },
  {
    path: "package-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/package-management/package-management.module"
      ).then((m) => m.PackageManagementModule),
  },
  {
    path: "user-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/user-management/user-management.module"
      ).then((m) => m.UserManagementModule),
  },
  {
    path: "collection-point-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/collection-point-management/collection-point-management.module"
      ).then((m) => m.CollectionPointManagementModule),
  },
  {
    path: "order-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/order-management/order-management.module"
      ).then((m) => m.OrderManagementModule),
  },
  {
    path: "custom-order-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/custom-order-management/custom-order-management.module"
      ).then((m) => m.CustomOrderManagementModule),
  },
  {
    path: "complaint-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/complaint-management/complaint-management.module"
      ).then((m) => m.ComplaintManagementModule),
  },
  {
    path: "income-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/income-management/income-management.module"
      ).then((m) => m.IncomeManagementModule),
  },
  {
    path: "payout-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/payout-management/payout-management.module"
      ).then((m) => m.PayoutManagementModule),
  },
  {
    path: "penalty-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/penalty-management/penalty-management.module"
      ).then((m) => m.PenaltyManagementModule),
  },
  {
    path: "terms-management",
    loadChildren: () =>
      import("src/app/features/admin/terms/terms.module").then(
        (m) => m.TermsModule
      ),
  },
  {
    path: "compensation-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/compensation-management/compensation-management.module"
      ).then((m) => m.CompensationManagementModule),
  },
  {
    path: "platform-setting-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/platform-setting-management/platform-setting-management.module"
      ).then((m) => m.PlatformSettingManagementModule),
  },
  {
    path: "delivery-type-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/delivery-type-management/delivery-type-management.module"
      ).then((m) => m.DeliveryTypeManagementModule),
  },
  {
    path: "bag-type-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/bag-type-management/bag-type-management.module"
      ).then((m) => m.BagTypeManagementModule),
  },
  {
    path: "order-type-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/order-type-management/order-type-management.module"
      ).then((m) => m.OrderTypeManagementModule),
  },
  {
    path: "delivery-area-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/delivery-area-management/delivery-area-management.module"
      ).then((m) => m.DeliveryAreaManagementModule),
  },

  {
    path: "laundries-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/laundry-management/laundry-management.module"
      ).then((m) => m.LaundryManagementModule),
  },

  {
    path: "discount-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/discount-management/discount-management.module"
      ).then((m) => m.DiscountManagementModule),
  },
  {
    path: "sale-point-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/sale-point-management/sale-point-management.module"
      ).then((m) => m.SalePointManagementModule),
  },
  {
    path: "pick-up-time-management",
    loadChildren: () =>
      import(
        "src/app/features/admin/pick-up-time-management/pick-up-time-management.module"
      ).then((m) => m.PickUpTimeManagementModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
