import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/shared/constants/endpoints';
import { environment } from 'src/environments/environment';
import { CreateSalePointeDto } from '../dtos/create-sale-point.dto';

@Injectable({
  providedIn: 'root'
})
export class SalePointService {

  constructor(private http: HttpClient) { }

  getAll = () => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.SALE_POINTS}`);
  create = (CreateSalePointeDto: CreateSalePointeDto) =>
    this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.SALE_POINTS}`, CreateSalePointeDto);

}
