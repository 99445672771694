import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalePointListComponent } from './pages/sale-point-list/sale-point-list.component';
import { CreateUpdateSalePointComponent } from './components/create-update-sale-point/create-update-sale-point.component';

const routes: Routes = [{
  path: '',
  component: SalePointListComponent
},
{
  path: 'create-update',
  component: CreateUpdateSalePointComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalePointManagementRoutingModule { }
