import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { SalePointService } from '../../services/sale-point.service';
import { CreateUpdateSalePointComponent } from '../../components/create-update-sale-point/create-update-sale-point.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sale-point-list',
  templateUrl: './sale-point-list.component.html',
  styleUrls: ['./sale-point-list.component.scss']
})
export class SalePointListComponent implements OnInit {

  @ViewChild('search') searchElementRef: ElementRef;
  // google maps zoom level
  zoom: number = 6;

  // initial center position for the map
  lat: number = 24.774265;
  lng: number = 46.738586;
  address: any;
  web_site: any;
  name: any;
  zip_code: any;
  salePoints: any;

  constructor(private mapsAPILoader: MapsAPILoader,
    private salePointService: SalePointService,
    private ngZone: NgZone,
    public dialog: MatDialog,) { }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place)
          this.address = place.formatted_address;
          this.web_site = place.website;
          this.name = place.name;
          this.zip_code = place.address_components[place.address_components.length - 1].long_name;
          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 16;

        });
      });
    });
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {

  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }





  getSalePoints = () => this.salePointService.getAll().pipe(take(1)).subscribe(
    (res: any) => { if (res.data) this.salePoints = res.data }
  )

  ngOnInit(): void {
    this.findAdress();
    this.getSalePoints();

  }

  openCreateUpdateDialog = (salePoint?: any) => {
    if (salePoint) {
      const dialogRef = this.dialog.open(CreateUpdateSalePointComponent, {
        data: salePoint,
        direction: 'rtl',
        width: '800px'
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result) this.getSalePoints()
      });
    } else {
      const dialogRef = this.dialog.open(CreateUpdateSalePointComponent, {
        data: {},
        direction: 'rtl',
        width: '800px'
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result) this.getSalePoints()
      });
    }


  }


}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
}
