<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body"><span>
      {{getAdmin()?.user?.fullName}}
    </span>
    <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a routerLink="/v1/admin/settings/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>
     الحساب    </span></a></li>

  <li><a (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>
        تسجيل الخروج
      </span></a></li>
</ul>