import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LaundryRoutingModule } from './laundry-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LaundryRoutingModule,
  ]
})
export class LaundryModule { }
