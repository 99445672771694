

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        قائمة نقاط البيع
                    </h5><span>إدارة نقاط البيع</span>
                    <button (click)="openCreateUpdateDialog()" class="float-right"  mat-raised-button color="primary">
                        إضافة نقطة بيع
                    </button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">

                            <mat-form-field
                                appearance="outline" class="w-100">
                                <mat-label>
                                    أدخل الموقع
                                </mat-label>
                                <input #search autocorrect="off"
                                    autocapitalize="off" spellcheck="off"
                                    placeholder="أدخل الموقع"
                                    type="text"
                                    matInput>

                            </mat-form-field>

                            <br>

                            <agm-map
                                [latitude]="lat"
                                [longitude]="lng"
                                [zoom]="zoom"
                                [disableDefaultUI]="false"
                                [zoomControl]="false"
                                (mapClick)="mapClicked($event)">

                                <agm-marker
                                    *ngFor="let m of salePoints; let i=
                                    index"
                                    (markerClick)="removeCollectionPoint(m.id)"
                                    [latitude]="m.latitude"
                                    [color]="'blue'"
                                    [longitude]="m.longitude">
                                    <agm-info-window>
                                        <strong class="p-4">{{m.name}}</strong>
                                    </agm-info-window>

                                </agm-marker>

                            </agm-map>
                       

                   
                        </div>
                      

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>