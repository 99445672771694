import { Component, OnInit } from '@angular/core';
import { endpoints } from 'src/app/shared/constants/endpoints';
import { environment } from 'src/environments/environment';
import { SseService } from '../../../../services/sse.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  notifications: any;
  notifCount: number = 0;
  constructor(private sseService: SseService, private notificationsService: NotificationsService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.getNotifications();
    this.sseService.getServerSentEvent(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.NOTIFICATIONS}/sse`,
      localStorage.getItem("access_token"))
     .subscribe(data => {
        this.toastr.show(
          JSON.parse(data.data).notification.content,
          JSON.parse(data.data).notification.title);


        //  console.log(data.data.notification);
        this.getNotifications();
      });
  }

  getNotifications() {
    return this.notificationsService.getNotifications().pipe(take(1)).subscribe(
      (res: any) => {
        this.notifications = res.data
        this.notificationsService.getUnseenNoificationCount().pipe(take(1)).subscribe(
          (res: any) => {
            this.notifCount = res.data.notifCount
          }
        )
      }
    )
  }

  markAsAllSeen() {
    return this.notificationsService.markAsAllSeen().pipe(take(1)).subscribe(
      (res: any) => {
        this.getNotifications()
      }
    )
  }

  markAsSeen(id: string) {
    return this.notificationsService.markAsSeen(id).pipe(take(1)).subscribe(
      (res: any) => {
        this.getNotifications()
      }
    )
  }

  markAsUnSeen(id: string) {
    return this.notificationsService.markAsUnSeen(id).pipe(take(1)).subscribe(
      (res: any) => {
        this.getNotifications()
      }
    )
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
