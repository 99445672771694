import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { EventSourcePolyfill } from 'event-source-polyfill';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  constructor(private _zone: NgZone) {}
  
  getServerSentEvent(url: string, token: string): Observable<any> {
    return Observable.create(observer => {
      const eventSource = new EventSourcePolyfill(`${url}`,{
        headers: {
            'Authorization': 'Bearer ' + token
        },
        heartbeatTimeout: 300000
    }, 
      );
      eventSource.onmessage = event => {
        this._zone.run(() => {
          observer.next(event);
        });
      };
      eventSource.onerror = error => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
    });
  }
  private getEventSource(url: string): EventSource {
    return new EventSource(url);
  }
}
