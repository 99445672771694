


<div class="row">
    <div class="col-sm-12">
        <div class="card">

            <div class="card-body">
                <form [formGroup]="createSalePointForm">

                    <mat-form-field
                        appearance="outline" class="w-100">
                        <mat-label>
                            أدخل الموقع
                        </mat-label>
                        <input #search autocorrect="off"
                            autocapitalize="off" spellcheck="off"
                            placeholder="أدخل الموقع"
                            type="text"
                            matInput>

                    </mat-form-field>



                    <agm-map


                        [latitude]="lat"
                        [longitude]="lng"
                        [zoom]="zoom"
                        [disableDefaultUI]="false"
                        [zoomControl]="false"
                        (mapClick)="mapClicked($event)">

                        <agm-marker

                            [markerDraggable]="true"
                            [latitude]="lat"
                            (dragEnd)="markerDragEnd($event)"
                            [longitude]="lng">


                        </agm-marker>

                    </agm-map>



                    <mat-form-field
                        appearance="outline" class="w-100">
                        <mat-label>اسم نقطة البيع</mat-label>
                        <input formControlName="name" matInput
                            #input>
                    </mat-form-field>

                    <button (click)="createSalePoint()" mat-raised-button color="primary">
                        إضافة نقطة بيع
                    </button>
                </form>

            </div>
        </div>
    </div>
</div>
