import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { SalePointService } from '../../services/sale-point.service';
import { AgmMap, MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-create-update-sale-point',
  templateUrl: './create-update-sale-point.component.html',
  styleUrls: ['./create-update-sale-point.component.scss']
})
export class CreateUpdateSalePointComponent implements OnInit, AfterViewInit {

  @ViewChild('search') searchElementRef: ElementRef;
  // google maps zoom level
  zoom: number = 6;

  @ViewChild('pickupMap') pickMap: AgmMap;



  // initial center position for the map
  lat: number = 24.774265;
  lng: number = 46.738586;
  address: any;
  web_site: any;
  name: any;
  zip_code: any;
  collectionPoints: any;

  public createSalePointForm: FormGroup; 

  constructor(private mapsAPILoader: MapsAPILoader,
    public dialogRef: MatDialogRef<CreateUpdateSalePointComponent>,
    private salePointService: SalePointService,
    private ngZone: NgZone,
    private fb: FormBuilder) {

    this.createSalePointForm = this.fb.group({
      name: ['', [Validators.required]],
    });


  }
  ngAfterViewInit(): void {
    this.findAdress();
  }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place)
          this.address = place.formatted_address;
          this.web_site = place.website;
          this.name = place.name;
          this.zip_code = place.address_components[place.address_components.length - 1].long_name;
          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 16;

        });
      });
    });
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
    this.lat = $event.coords.lat
    this.lng = $event.coords.lng
  }

  markerDragEnd($event: MouseEvent) {

    this.lat = $event.coords.lat
    this.lng = $event.coords.lng
  }


  ngOnInit(): void {

  }

  createSalePoint = () => this.salePointService.create({
    ...this.createSalePointForm.getRawValue(),
    longitude: this.lng,
    latitude: this.lat
  }).pipe(take(1)).subscribe(
    res => this.dialogRef.close(res)
  )

}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
}
