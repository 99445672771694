export const endpoints = {
    ADMIN: {
        LOGIN: 'auth/login/admin',
        BACKOFFICE: {
            AGENT: 'bo-admin-agents',

            SERVICES: 'bo-admin-services',
            TERMS: 'bo-admin-terms',
            PLATFORM_CONFIGS: 'bo-admin-platform-configs',
            PLATFORM_INFO: 'bo-admin-platform-info',
            COLLECTION_POINTS: 'bo-admin-collection-points',
            SALE_POINTS: 'bo-admin-sale-points',
            PACKAGES: 'bo-admin-packages',
            ACCOUNTS: 'bo-admin-accounts',
            BAGS: 'bo-admin-bags',
            ORDERS: 'bo-admin-orders',
            INCOMES: 'bo-admin-incomes',
            PENALTIES: 'bo-admin-penalties',
            PAYOUTS: 'bo-admin-payouts',
            BALANCE_ACTIVITIES: 'bo-admin-balance-activities',
            DELIVERY_AREAS: 'bo-admin-delivery-areas',
            COMPENSATIONS: 'bo-admin-compensations',
            COMPLAINTS: 'bo-admin-complaints',
            CONTACT_MESSAGES: 'bo-admin-contact-messages',
            CUSTOM_ORDERS: 'bo-admin-custom-orders',
            OFFERS: 'bo-admin-offers',
            CATEGORIES: 'bo-admin-categories',
            NOTIFICATIONS: 'bo-admin-notifications',
            TYPES: 'bo-admin-types',
            PRODUCTS: 'bo-admin-products',
            ITEMS: 'bo-admin-items',
            PICKUPTIMES: 'bo-admin-pick-up-times',
            USERS: 'bo-admin-users',
            LAUNDRIES: 'bo-admin-laundries',
            LAUNDRY_INCOMES: 'bo-admin-laundry-incomes',
            DELIVERY_TYPES: 'bo-admin-delivery-types',
            ORDER_TYPES: 'bo-admin-order-types',
        }
    },
    LAUNDRY: {
        REGISTER: 'auth/register/laundry-owner',
        LOGIN: 'auth/login/laundry-owner',
        PRODUCTS: 'bo-products',
        LAUNDRIES: 'bo-laundries',
        OPENING_HOURS: 'bo-opening-hours'
    },
    REGION: 'regions'
   
}

