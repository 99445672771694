import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { endpoints } from '../constants/endpoints';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private http: HttpClient) { }

    getNotifications() {
        return this.http.get( `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.NOTIFICATIONS}`)
    }

    getUnseenNoificationCount() {
        return this.http.get( `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.NOTIFICATIONS}/mine/useen-count`)
    }

    markAsAllSeen() {
        return this.http.patch( `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.NOTIFICATIONS}/make-as-seen`,{})
    }

    markAsSeen(id: string) {
        return this.http.patch( `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.NOTIFICATIONS}/${id}/check`,{})
    }

    markAsUnSeen(id: string) {
        return this.http.patch( `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.NOTIFICATIONS}/${id}/uncheck`,{})
    }

}
