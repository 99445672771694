import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalePointManagementRoutingModule } from './sale-point-management-routing.module';
import { SalePointListComponent } from './pages/sale-point-list/sale-point-list.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CreateUpdateSalePointComponent } from './components/create-update-sale-point/create-update-sale-point.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SalePointListComponent,
    CreateUpdateSalePointComponent
  ],
  imports: [
    CommonModule,
    SalePointManagementRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.MAPS_API_KEY,
      libraries: ["places"]
    }),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class SalePointManagementModule { }
